<template>
  <div class="prices-view">
    <h1>Lista produse de calculat pret</h1>

    <div>
      <section>
        <h2>1. Talpa metalica</h2>
        <p>Dorim pret pentru talpa din imagine, cu urmatoarele specificatii: <br>
          - pret pentru 10/50/100 de bucati, <br>
          - pret cu si fara gravare nume brand. <br>
          - In centru trebuie sa intre un lemn de 10x10cm. <br>
          - placa inferioara 15x15cm. <br>
          - gauri ca in poza <br>
          - diametru gauri 0.6 cm <br>
          - powder coated negru <br>
          - 14-gauge Canadian Steel <br>
          - nu e tabla <br>
        </p>
        <div class="pswp-gallery pswp-gallery--single-column"
             id="my-gallery">
          <a v-for="(item, index) in imgs"
             :key="index"
             :href="`https://goodproducts.testenv.ro/img/${item.name}.jpeg`"
             :data-pswp-width="item.w"
             :data-pswp-height="item.h"
             target="_blank">
            <img :src="require(`../assets/talpa/${index + 1}.jpeg`)" alt="" />
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
  import PhotoSwipeLightbox from 'photoswipe/lightbox';
  import 'photoswipe/style.css';
  import { onMounted } from 'vue'

  const imgs = [
    {name: '1.f1706010', w: '1024', h: '1024'},
    {name: '2.7179033d', w: '1024', h: '1024'},
    {name: '3.2e9174b9', w: '1024', h: '1024'},
    {name: '4.c4d1b551', w: '1238', h: '566'},
    {name: '5.b291054a', w: '1600', h: '804'}
  ]

  onMounted(function () {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#my-gallery',
      children: 'a',
      pswpModule: () => import('photoswipe')
    });
    lightbox.init();
  })
</script>

<style lang="scss">
  .prices-view {
    @media only screen and (min-width: 0) {
      padding: 16px;
      > div {
        max-width: 1060px;
        margin: 0 auto;
      }
      section {
        text-align: left;
        figure, a {
          img {
            max-width: 320px;
            margin-right: 16px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
</style>