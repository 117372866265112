import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    products: [],
    reqStatus: 'idle',
    data: []
  },
  getters: {
  },
  mutations: {
    SET_STATE (state, {key, value}) {
      state[key] = value
    },
    SET_PRODUCT (state, imgName) {
      const index = state.products.findIndex(val => val === imgName)
      if (index > -1) {
        state.products.splice(index, 1)
      } else {
        state.products.push(imgName)
      }
    }
  },
  actions: {
    async get_products ({state, commit}) {
      try {
        const { data } = await axios.get(`/api/products`)
        commit('SET_STATE', {key: 'data', value: data})
      } catch (e) {
        console.log(e)
      }
    },
    async save_selection ({state, commit}) {
      try {
        const { data } = await axios.post(`/api/products`, state.products)
        commit('SET_STATE', {key: 'reqStatus', value: 'saved'})
      } catch (e) {
        console.log(e)
      }
    }
  },
  modules: {
  }
})
