<template>
  <main class="home-page">
    <div v-if="reqStatus === 'idle'">
      <h1>Produse utile pentru casa</h1>

      <ul>
        <li v-for="(item, index) in 244"
            :key="index">
          <figure>
            <img :src="require(`../assets/images/${index}.jpg`)" alt="" />
          </figure>
          <label :for="index">
            <input :id="index"
                   @input="handleInput(index)"
                   type="checkbox" />
            <span>sunt interesat</span>
          </label>
        </li>
      </ul>

      <footer>
        <button @click="submit">Salveaza</button>
      </footer>
    </div>

    <div v-if="reqStatus === 'saved'">
      <h1>Selectia a fost salvata cu succes! <br> Multumesc.</h1>
    </div>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const { commit, dispatch, state } = useStore()
  const reqStatus = computed(() => state.reqStatus)
  function handleInput (index) {
    commit('SET_PRODUCT', index)
  }

  function submit () {
    dispatch('save_selection')
  }
</script>

<style>
  .home-page {
    @media only screen and (min-width: 0) {
      padding: 24px 24px 120px;
      h1 {
        font-size: 3rem;
        margin-bottom: 48px;
      }
      ul {
        max-width: 1320px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      li {
        margin-right: 12px;
        margin-bottom: 24px;
        padding: 12px;
        border-radius: 8px;
        background-color: white;
        list-style-type: none;
        border: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        figure {
          max-width: 320px;
          img {
            width: 100%;
          }
        }
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin-left: 6px;
          font-size: 1.8rem;
          display: inline-block;
        }
      }

      footer {
        width: 100%;
        background-color: white;
        padding: 16px;
        border-top: 1px solid gray;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          cursor: pointer;
          font-size: 2rem;
          background-color: #1eb386;
          border: none;
          color: white;
          border-radius: 4px;
          padding: 4px 24px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      li {
        &:hover {
          outline: 2px solid #1eb386;
        }
      }
      label {
        input {
          cursor: pointer;
        }
        cursor: pointer;
      }
    }
  }
</style>
